export const headItems = [
  "Name",
  "Email",
  "Status",
  "User Type",
  "Total Spend $",
  "Last 30 days spent $",
  "Date",
  "Actions",
];

export const adminDetails = [
  {
    email: "dudinak@gmail.com",
    password: "123456",
  },
];
